import { type Seance, SeanceAdapter, type SeanceDTO } from '@/5_entities/Seance'
import type { Venue } from '@/5_entities/Venue'
import type { SearchResult } from '@/6_shared/model'
import type { Event, EventDTO } from './type'

export class EventAdapter implements Event {
  public id: Event['id']
  public title: Event['title']
  public seances: Event['seances']
  public promo: Event['promo']
  public gallery: Event['gallery']
  public image: Event['image']
  public description: Event['description']
  public timeInfo: Event['timeInfo']
  public ageCategory: Event['ageCategory']
  public eventSeancesCount: Event['eventSeancesCount']
  public seancesPagination: Event['seancesPagination']
  public organizer: Event['organizer']
  public categoryId: Event['categoryId']

  constructor(data: EventDTO) {
    this.id = data.id
    this.title = data.title
    this.seances = Array.isArray(data?.seances?.items) ? this._setSeances(data) : []
    this.eventSeancesCount = data?.seances?.pagination?.totalCount || 0
    this.promo = data.promo
    this.gallery = this._setGallery(data.media.gallery)
    this.image = data.media?.posterImage
    this.description = data?.fullDescription
    this.timeInfo = data?.timeInfo
    this.ageCategory = data?.ageCategory
    this.seancesPagination = data?.seances?.pagination
    this.organizer = data?.organizer
    this.categoryId = data?.categoryId
  }

  public get isPushkin() {
    return this.promo?.badges?.find((item) => item.type === 'isPushkin')?.value || false
  }

  private _setGallery(gallery: EventDTO['media']['gallery']) {
    return Array.isArray(gallery)
      ? gallery.map((item, counter) => {
          return {
            ...item,
            object: {
              ...item.object,
              id: item.object.id ?? counter
            }
          }
        })
      : []
  }

  public get venues() {
    return this.seances.map((seance) => seance.venue).filter((item) => !!item) as Venue[]
  }

  private _setSeances(event: EventDTO) {
    return (
      event.seances?.items
        .map((item) => new SeanceAdapter(item.object))
        .filter((seance: Seance) => !!seance.ticketsLeft) ?? []
    )
  }

  public addSeancesNextPage(newSeances: SearchResult<'seance', SeanceDTO>) {
    const { pagination, items } = newSeances
    this.seancesPagination = pagination
    const seances = items.map((item) => new SeanceAdapter(item.object))
    seances.forEach((seance) => this.seances.push(seance))
  }
}
